export const contents = [
  {
    id: 0,
    time: "2022-10-15",
    name: "国能河北蠡县热电项目线环境影响评价第一次信息公示",
    message:
      "建设项目名称：国能河北蠡县热电项目铁路专用线\n" +
      "\n" +
      "建设单位名称：国能保定发电有限公司\n" +
      "\n" +
      "项目建设地点：河北省保定市蠡县仉村村西\n" +
      "\n" +
      "主要建设内容及规模：线路自蠡县站东端牵出线引出后，折向东北走行，跨越南水北调保沧干渠、G240，在园区东侧设置电厂站。线路全长2.66km。电厂站内布置1条重车线，1条轻车线，有效长均满足1050m，1条机走线。设边修线1条，机待线1条；尾部设置翻车机（不在本工程范围内），设轨道衡1处。预留1条重车线，1条轻车线。蠡县站预留到发线1条。",
  },
  {
    id: 1,
    time: "2022-10-15",
    name: "2022年蠡县政府网站年度公开报表",
    message: "详见附件。\n" + "\n",
  },
  {
    id: 2,
    time: "2022-10-15",
    name: "蠡县人民政府办公室关于开展规范性文件清理工作的通知",
    message:
      "蠡县人民政府办公室\n" +
      "\n" +
      "关于开展规范性文件清理工作的通知\n" +
      "\n" +
      " \n" +
      "\n" +
      "各乡镇政府，县政府各部门：\n" +
      "\n" +
      "为全面贯彻落实党的十九大和省委九届八次全会精神，深入推进“放管服”改革，持续深入推进全县营商环境优化，按照今年法治政府建设工作部署，根据《河北省人民政府办公厅关于开展规章规范性文件清理规章的通知》（〔2019〕-62）和《保定市人民政府办公室关于切实做好规章规范性文件清理工作的通知》要求，经县政府同意，决定对规范性文件进行一次全面清理。现将有关事项通知如下：\n" +
      "\n" +
      "一、清理范围\n" +
      "\n" +
      "2018年12月31日前，各乡镇政府、县政府各部门以及法律法规授权管理公共事务职能的组织制定的现行有效的全部规范性文件。\n" +
      "\n" +
      "二、清理依据\n" +
      "\n" +
      "各乡镇政府、县政府各部门要认真落实国家、省市出台的有关推进“放管服”改革、优化营商环境、依法平等保护各类产权及其他政策措施，法律、法规、规章修改、废止情况，结合党中央、国务院和国务院相关部门以及省市部署的规章规范性文件专项清理要求，对现行有效的规范性文件进行全面清理。\n" +
      "\n" +
      "三、清理职责\n" +
      "\n" +
      "按照“谁起草实施，谁负责提出清理意见”的原则，规范性文件由制定机关负责清理，制定机关被撤销或职权已调整的，由继续行使其职权的机关负责清理。\n" +
      "\n" +
      "县政府规范性文件的清理，由起草实施部门提出清理意见和建议，经县司法行政机关审核后，报县政府决定。\n" +
      "\n" +
      "部门规范性文件由制定机关自行组织清理，部门联合制定的和涉及多个部门职责的，由牵头部门征求相关部门意见后作出清理决定，清理结果报县司法局备案。",
  },
  {
    id: 3,
    time: "2022-10-15",
    name: "学习贯彻落实党的二十大精神|市委宣讲团",
    message:
      "为切实推动党的二十大精神在基层一线“广覆盖、传正声、叫响亮”。2月6日，市委党的二十大精神宣讲团成员、河北工艺美术职业学院党委副书记赵云耕教授到我县，为蠡吾镇和各社区广大党员干部、社区群众进行党的二十大精神宣讲。\n" +
      "\n" +
      "赵云耕以“谱写中国特色社会主义更加绚丽的华章”为主题，以党员群众的视角、接地气的语言、听的懂的道理、讲故事的方式、紧紧围绕“以史为鉴可以知兴替”“坚持和发展马克思主义，必须同中国具体实际相结合”“习近平新时代中国特色社会主义思想”，对党的二十大精神进行了生动诠释和解读。\n" +
      "\n" +
      "整场宣讲主体突出、内容丰富，既有宏观解读、也有微观剖析、帮助基层干部群众更加准确把握、全面领会党的二十大精神。",
  },
  {
    id: 4,
    time: "2022-9-22",
    name: "崇德敬业 患者至上——记嘉峪关市优秀共产党员邵平乐",
    message:
      "记者 赵明霞 \n" +
      "\n" +
      "　　从医25年来，邵平乐始终恪守医务人员道德规范，用共产党员的标准严格要求自己，以高尚的医德，精湛的医术赢得了广大患者和同行的赞誉，先后荣获全省卫生系统青年岗位能手，嘉峪关市“市级领军人才”、嘉峪关市“五一劳动奖章”、“雄关名医”等荣誉称号，并担任中国医师协会超声医师分会委员会委员，甘肃省医学会超声医学分会委员，甘肃省抗癌协会甲状腺癌委员会常务委员，嘉峪关超声医学质量控制中心专家组主任。\n" +
      "\n" +
      "　　崇德敬业 患者至上\n" +
      "\n" +
      "　　邵平乐始终把党的全心全意为人民服务宗旨与自己的工作联系在一起，关心、爱护、尊重患者，恪守救死扶伤、治病救人的神圣职责，不断提高自己的政治理论水平。他积极参加各项政治学习，牢固树立“以人为本”的理念，充分体现患者的主体地位，充分尊重患者的权利和权益，并将这一理念真正落实到工作中。无论是在节假日还是在休息时间，遇有急诊或疑难患者时，他均能以最快的速度赶到医院，给患者做细致的检查，他用实际行动在院内树立了好医生的标杆。\n" +
      "\n" +
      "　　刻苦钻研 业务精湛\n" +
      "\n" +
      "　　邵平乐热爱学习，将书本理论与临床实践相结合，珍惜进修学习机会，不断汲取新的医疗知识，积极探索临床经验，不断提高自身业务技术水平，使自己在专业上不断成熟，年接诊患者逾6000人次，从未发生过医疗事故，无不良投诉。在他的带动下，嘉峪关市中医院超声科除开展常规业务工作之外，还开辟了许多新的项目，为临床医师诊疗提供准确可靠的依据；开展肝囊肿、肾囊肿、卵巢囊肿及肾上腺囊肿的超声介入治疗，在超声引导下的穿刺活检，乳腺微小肿块术前定位钢丝的置入术，超声造影检查等新技术的开展上走在本地区的前列，在我市率先开展了系统胎儿超声筛查，胎儿畸形检出率一直居于市内领先水平。\n" +
      "\n" +
      "　　团结协作 率先垂范\n" +
      "\n" +
      "　　邵平乐经常性组织业务学习、病例讨论，组织科室人员积极参加院内外的业务学习，拓宽视野，学习新的医疗知识及技术，在科室内兴起了钻研业务知识的风气，不断提高科室整体业务技术水平。遇有疑难少见病例，他立即组织科室在岗人员集中学习，这种临时性的业务学习方式，有益于科室整体水平提高。他帮助科内年轻医生，外院进修医师在较短的时间内掌握常见病、多发病的超声诊断，帮助年轻医生快速成长，把自己的技术无私地传授给年轻医生。 ",
  },
  {
    id: 5,
    time: "2022-9-22",
    name: "一辈子践行初心使命的“花萼愚公”",
    message:
      "　周永开，男，汉族，四川巴中人，1928年3月出生，1945年8月参加工作，同年同月加入中国共产党，四川省原达县地委副书记，1991年6月离休。\n" +
      "\n" +
      "　　周永开同志是践行共产党人初心使命的典范，是党员干部学习的榜样。他对党的事业无限忠诚，解放前冒着生命危险从事川北地区党的地下工作，新中国成立后全心全意为百姓造福，一辈子听党话、跟党走，用实际行动践行“党是一生的追随”的座右铭。在职期间，他恪尽职守、苦干实干，带领广大群众植树造林；顶着压力查办案件，坚决同腐败行为作斗争。离休后，他积极投身生态环境保护事业，牵头组建退休干部义务护林队，不遗余力参与万源市花萼山护林造林，推动花萼山建成国家级自然保护区，始终以旺盛的革命热情践行“人可以离休但共产党员永不会离休”的朴实承诺。他把群众当亲人，多次拿出离休费捐资助学、扶贫济困，先后捐资18.9万余元，连续10余年资助数十名花萼山贫困学生。他自掏腰包为花萼山群众购买中药材种苗，推动成立川陕萼贝专业合作社，带动老区人民规模种植、脱贫致富，被群众亲切地称为“周老革命”。他对自己和家人始终严格要求，主动放弃单位集资建房选房名额，至今仍住在上世纪80年代修建的老家属院，绝大部分家具仍然是上世纪90年代的用品。周永开同志几十年如一日坚守共产党人的初心使命，把一辈子奉献给了革命老区，奉献给了花萼山，被当地人称颂为“花萼愚公”。",
  },
  {
    id: 6,
    time: "2022-9-22",
    name: "把一生献给党和祖国的“布衣”院士\n" + "\n",
    message:
      "　　卢永根，男，汉族，1930年12月出生于香港，祖籍广东省广州市，1947年12月参加工作，1949年8月加入中国共产党，华南农业大学原校长、教授、博士生导师，中国科学院院士。2019年8月12日，因病医治无效逝世，享年89岁。\n" +
      "\n" +
      "　　卢永根同志一生学农、爱农、为农，全心全意奋斗在祖国最需要的地方，把毕生精力献给了祖国的农业科学和教育事业。他主持完成的《中国水稻品种的光温生态》，成为我国水稻育种工作者最重要的参考书之一，获得全国科学大会奖。他和助手提出的水稻“特异亲和基因”的新学术观点以及相关设想，被认为是目前对栽培稻杂种不育性和亲和性比较完整系统的新认识，对水稻育种实践具有指导意义。他常年跋山涉水寻找野生稻，在继承导师丁颖生前收集的7000多份野生稻种基础上，逐步扩充到1万多份水稻种质资源，为我国水稻种质资源收集、保护、研究和利用作出巨大贡献。他担任华南农业大学校长12年间，始终把党的教育事业放在首位，以身作则公而忘私，大刀阔斧推动改革，不拘一格选人用人，深受广大师生的崇敬和爱戴。他坚持“科学家有祖国”，把爱国之情、报国之志自觉融入中华民族伟大复兴的历史进程中。他时刻以党员标准严格要求自己，即便晚年身患重病卧床，仍然坚持参加病房临时党支部组织生活，认真学习习近平新时代中国特色社会主义思想。他穿不讲究、吃不挑剔，家中一床简席、四壁白墙，却将一辈子省吃俭用积攒下的880余万元全部捐献给华南农业大学，用于支持农业教育事业，还提前办理了遗体捐献卡，去世后将遗体无偿捐献给医学科研事业。",
  },
  {
    id: 7,
    time: "2022-9-22",
    name: "忠诚执着守初心 无私奉献担使命\n" + "\n",
    message:
      "　　张桂梅，女，满族，黑龙江牡丹江人，1957年6月出生，1975年12月参加工作，1998年4月加入中国共产党，云南省丽江华坪女子高级中学党支部书记、校长，华坪县儿童福利院（华坪儿童之家）院长。\n" +
      "\n" +
      "　　张桂梅同志对党忠诚，始终听党话、跟党走，在脱贫攻坚一线坚决贯彻党中央决策部署，坚守贫困地区40多年，把全部身心投入深度贫困山区教育扶贫主战场，在平凡的岗位上做出了不平凡的事业。她牢记宗旨，坚持为民情怀，为阻断贫困的代际传递，从2002年起锲而不舍四处奔走，克服资金难以筹集、贫困山区群众不理解不支持等一系列困难，在2008年8月成功创办全国第一所全免费女子高中，为贫困山区女孩的追梦人生点亮了希望之光。她坚守初心，坚韧执着，面对女高初创时条件艰苦、缺少师资力量的不利局面，推动发挥党员先锋模范作用和党支部战斗堡垒作用，坚持开展党员一律佩戴党员徽章上课、每周重温一次入党誓词、每周合唱一支革命歌曲、每周观看一部红色影片、每周组织一次理论学习的“五个一”活动，以党建引领办教育，帮助1804名贫困山区女孩考上大学、走出大山，连续10年高考综合上线率保持100%。在1997年4月查出患有子宫肌瘤后，她仍然坚持站在讲台上教书育人，坚持家访11年，行程近11万公里，覆盖学生1345名。为让失去双亲的孤儿有一个“家”，她从2001年起一边任教、一边兼任华坪县儿童福利院院长，当好136个孤儿的“妈妈”，被评为“全国百名优秀母亲”。她将自己的工资、奖金和社会各界捐款累计100多万元全部投入教育事业，把一切都献给了党、献给了贫困山区的孩子们。",
  },
  {
    id: 8,
    time: "2022-9-22",
    name: "浉河区湖东办事处积极推进党建统领基层治理网格化工作\n",
    message:
      "进一步夯实网格基础，充分发挥网格员职能，湖东办事处按照市、区党建统领基层治理工作部署，不断健全和完善网格化组织体系，全力推进网格化工作高效运行。\n" +
      "\n" +
      '一是配强网格力量，标准设置网格公示牌。湖东办事处按照建立标准网格化要求，结合本辖区实际，合理调整划分三级网格60个，配齐配全了各网格内"一长三员"。在各社区党群服务中心设置公示，将社区网格组织架构、人员、服务区域及工作职责、要求进行公示。在各三级网格安装制作公示牌218块，群众通过扫描公示牌上的二维码就可以了解所在网格的基本信息和相关服务内容。以三五八社区为试点，探索完善社区网格工作站建设，为社区网格员更好服务群众提供坚实保障。\n' +
      "\n" +
      "\n" +
      "\n" +
      "二是组织业务培训，提升网格员服务能力。5月26日，湖东办事处组织全体专职网格员和二级网格管理员，召开专职网格员业务培训会。培训会上综治中心领学了《浉河区网格员管理办法》，对专职网格员的职责、任务、能力及如何做一名合格网格员进行详细讲解;政法委员对专职网格员如何巡查网格、开展矛盾纠纷化解、排查安全隐患等工作进行强调和要求，通过学习使网格员知道了干什么、怎么干，提升了实战水平。\n" +
      "\n" +
      "\n" +
      "\n" +
      '三是落实网格巡查，建全常态化运行机制。湖东办事处在坚持完成专职网格员十四项职责任务和"三必须""四必到""五必知"当好"六大员"工作要求的基础上，采取各网格员每天巡查网格两遍、社区每周召开网格例会、办事处每周通报网格上传民情事件排名等制度，进一步规范网格员管理，充分发挥网格员在维护社会稳定、防范化解矛盾和提供便民服务等方面的作用。',
  },
  {
    id: 9,
    time: "2022-9-22",
    name:
      "形式多、内容新、领悟深、党性强——党委组织开展“党建学习教育周”活动\n" +
      "\n",
    message:
      "党委理论学习中心组重温习近平总书记关于巡视工作的重要论述、在2022年春季学习中央党校中青年干部培训班开班式上的重要讲话精神、《中共中央关于党的百年奋斗重大成就和历史经验的决议》、《习近平新时代中国特色社会主义思想学习纲要》、《信访工作条例》等内容。在深学细照笃行中进一步领会“两个确立”的决定性意义，更加牢固树立正确的政绩观，更加自觉践行以人民为中心的发展思想，增强做到“两个维护”的政治自觉、思想自觉和行动自觉，为开好中央巡视整改专题民主生活会打牢思想基础。\n" +
      "\n" +
      "5月份，各党委成员分赴党建联系点开展调研，就中央巡视整改、党员学习教育、基层党组织标准化规范化建设等方面进行深入了解，现场指导联系点党建工作，提出了要坚持党建引领，为改革发展提供强大合力和内生动力；要不断强化理论武装，旗帜鲜明讲政治；要持续提升基层支部党建质量，充分发挥党支部战斗堡垒作用等具体要求。",
  },
  {
    id: 10,
    time: "2022-9-22",
    name: "财政局党委组织开展2023年2月份“主题党日”活动" + "\n",
    message:
      "春回大地，万象更新，潼关县财政局党委组织开展2月份“主题党日”活动。各下属党支部高度重视，精心策划，结合各自工作职能，在做实必有内容的基础上，结合实际开展好自选内容，丰富多彩，亮点纷呈。\n" +
      "\n" +
      "\n" +
      "\n" +
      "机关党支部\n" +
      "\n" +
      "2月2日下午，财政局党委机关党支部组织开展“深化共驻共建 促进社区治理”主题党日活动，活动由支部书记王江涛主持。\n" +
      "\n" +
      "Image\n" +
      "\n" +
      "活动在《前进吧，中国共产党》一首伟大的旗帜歌声中拉开序幕。随后，“我志愿加入中国共产党，拥护党的纲领，遵守党的章程，履行党员义务……”参会党员集体重温了入党誓词，字字入心，句句千钧，表达了每一名党员对党的赤诚忠心和为共产主义事业奋斗的坚定决心。\n" +
      "\n" +
      "Image\n" +
      "\n" +
      "Image\n" +
      "\n" +
      "活动集中学习了《习近平谈治国理政》、《二十大党章修正案学习问答》和《中国共产党重大事项请示报告条例》选节，对2022年度支部组织生活会以及2023年党员承诺践诺晒诺活动进行了安排部署。支部书记和纪检委员结合实际为我们大家进行了分享。现场交纳了2月份党费。\n" +
      "\n" +
      "活动要求，2023年机关党支部要以学习贯彻党的二十大精神和习近平新时代中国特色社会主义思想为指导，坚持“以政领财、以财辅政、党建引领、融合发展”理念，以打造支部党建品牌为抓手，认真谋划2023年财政重点亮点工作，全力服务保障县域经济发展大局，为谱写潼关高质量发展新篇章做出财政新贡献。\n" +
      "\n" +
      "会后，党委书记、局长王江涛组织下属党支部党员干部进入网格对接遍访活动。支部委员组织党员走进包联区域走访小区老、弱、病、残等重点人群，全面掌握情况。按照《关于进一步加强党建引领完善网格化管理城乡“治理一张网”建设社会治理共同体的实施方案的通知》要求 ，局党委组织下属各支部委员深入吴村社区，举行共驻共建签约仪式，要求下属党支部与共驻共建社区第五网格更加深入紧密的开展合作，定期组织党员干部走进社区开展教育活动、深入社区开展形式多样的志愿服务、开展内容丰富的联谊等喜闻乐见的活动，实现共建工作项目化、志愿服务经常化，务求活动常抓常新，创出特色，结出硕果。",
  },
  {
    id: 11,
    time: "2022-9-22",
    name:
      "【荣誉榜】全市优秀共产党员、优秀党务工作者和先进基层党组织名单" + "\n",
    message:
      "近年来，全市各级党组织和广大共产党员在市委的正确领导下，高举中国特色社会主义伟大旗帜，以马克思列宁主义、毛泽东思想、邓小平理论、“三个代表”重要思想和科学发展观为指导，认真学习贯彻党的十八大及十八届三中、四中、五中全会和习近平总书记系列重要讲话精神，深入开展党的群众路线教育实践活动、“三严三实”专题教育和“两学一做”学习教育，扎实推进先锋引领行动，在深化改革开放、推动科学发展、促进社会和谐、全面从严管党治党的各项工作中，充分发挥党组织的战斗堡垒和共产党员的先锋模范作用，涌现出了一大批先进基层党组织和优秀共产党员、优秀党务工作者。为表彰先进、树立典型、弘扬正气，营造学习先进、争当先进、赶超先进的干事创业氛围，市委决定，在庆祝建党95周年之际，对蒋吉福等100名共产党员、王海等20名党务工作者、甘州区安阳乡金王庄村党支部等50个基层党组织予以表彰，分别授予“优秀共产党员”、“优秀党务工作者”、“先进基层党组织”荣誉称号。\n" +
      "\n" +
      "这些受表彰的先进集体和先进个人，是全市各级党组织和广大党员中的优秀代表，他们以实际行动为党旗增辉添彩，为全市树立了榜样。他们的先进事迹集中展现了新时期各级党组织的崭新风貌和共产党人的先进性品质，具有鲜明的时代特征和典型意义。市委希望受表彰的优秀共产党员、优秀党务工作者和先进基层党组织，谦虚谨慎，戒骄戒躁，再接再厉，把荣誉作为新的起点，以更加充沛饱满的政治热情、更加奋发有为的精神状态、更加求真务实的工作作风，在推进全市经济和社会各项事业发展中再创佳绩、再立新功。\n" +
      "\n" +
      "市委号召，全市各级党组织和广大党员干部要结合正在开展的“两学一做”学习教育，以先进典型为榜样，学习他们信念坚定、对党忠诚、严守纪律、严守规矩的政治品质，学习他们开拓创新、敢于担当、奋力拼搏、积极进取的精神风貌，学习他们牢记宗旨、真心为民、立党为公、乐于奉献的高尚情操，学习他们爱岗敬业、恪尽职守、真抓实干、廉洁自律的优良作风，自觉适应新形势新任务的要求，深入学习贯彻党章党规和习近平总书记系列重要讲话精神，全面贯彻落实中央和省、市委的部署要求，在坚定贯彻“五大发展理念”，围绕“四二”部署，实施“六六”战略，加快建设幸福美好金张掖的征程中锐意进取、建功立业，做出更大的贡献。",
  },
  {
    id: 12,
    time: "2022-9-22",
    name: "平台积分规则获取" + "\n",
    message: "1. 阅读文章\n2. 互动参与",
  },
  {
    id: 13,
    time: "2022-9-22",
    name: "乡镇赋分规则" ,
    message: "1. 事件上报\n2. 领取任务",
  },
];
export const infos = {
  info: {
    tags: [
      {
        title: "惠民政策",
        data: [contents[2]],
      },
      { title: "宣传教育", data: [contents[3]] },
      {
        title: "当前要事",
        data: [contents[0], contents[1]],
      },
    ],
    data: [contents[0], contents[1]],
    name: "百姓要事",
  },
  peopleInfo: {
    tags: [],

    name: "政府要事",
    data: [
      {
        name: "请问如何申请就业见习？",
        message:
          "您好，来信已知悉。经人社局回复如下：关注”蠡县就业服务局“微信公众号，会发布相关通知。毕业两年内未就业的高校毕业生、16——24岁的失业青年可申请。感谢您的来信。",
      },
      {
        name: "蠡县城内公交几点停止运营？",
        message:
          "您好，来信已收悉。蠡县城内公交目前101 102线路末班发车时间为19：00，103线路末班发车时间为18：08，105线路末班发车时间为17：10。目前公交可免费乘坐，不收费。感谢您的来信。",
      },
    ],
  },
  gridInfo: {
    tags: [],
    name: "网格事项",
    data: [
      {
        name: "低碳出行积分申报",
        message: "请使用自主上报功能",
      },
      {
        name: "好人好事积分申报",
        message: "请使用自主上报功能",
      },
    ],
  },
  publicize: {
    tags: [
      {
        title: "道德模范",
        data: [contents[7]],
      },
      {
        title: "劳动模范",
        data: [contents[6]],
      },
      {
        title: "优秀共产党员",
        data: [contents[4]],
      },
      {
        title: "先进典型事迹",
        data: [contents[5]],
      },
    ],
    name: "百姓风采录",
  },
  rank: {
    tags: [
      {
        title: "红榜",
        data: [],
      },
      {
        title: "黑榜",
        data: [],
      },
    ],
    name: "红黑榜",
  },
  party: {
    tags: [
      {
        title: "信息公示",
        data: [contents[8]],
      },
      {
        title: "党员学习",
        data: [contents[9]],
      },
      {
        title: "党员活动",
        data: [contents[10]],
      },
      {
        title: "党员荣誉",
        data: [contents[11]],
      },
    ],
    name: "党建统领",
  },
  rule: {
    tags: [
      {
        title: "平台积分规则",
        data: [contents[12]],
      },
      {
        title: "乡镇赋分规则",
        data: [contents[13]],
      },
    ],
    name: "积分规则",
  },
};
